import React, { useEffect, useRef } from 'react'
import {outerContainer, image, titleContainer, titleStyle, previewContainer, previewHeader, previewContent, previewCta  } from './index.module.scss';
import {Link} from 'gatsby';

interface PreviewVidProps {
    src: string,
    to:string,
    title: string,
    type: string,
    cta: string
}
const PreviewCard = ({src,type,title,to, cta}:PreviewVidProps) => {

    const videoRef = useRef<HTMLVideoElement>(null);

    const play = ()=> {
      videoRef.current?.play();
    };

    useEffect(()=>{ 
       play();
    },[]);

    return (
        <Link to={to} className={outerContainer} >
                <div className={titleContainer}>
                    <h3 className={titleStyle}>{title}</h3>
                </div>
                <div className={previewContainer}>
                    <h4 className={previewHeader}>{title}</h4>
                    <p className={previewContent}>{type}</p>
                    <h5 className={previewCta}>{cta}</h5>
                </div>
                <div className={image}>
                <video
                 ref={videoRef}
                 loop
                 muted={true}
                 playsInline={true}
                className={image}>
                <source src={src} type="video/mp4" />
               </video>  
                </div>
                </Link>
    );
}


export default PreviewCard;

