import * as React from "react"
import "../styles/base/index.scss"
import {previewCardListContainer,bannerContainer} from '../styles/layout/main/index.module.scss'
import VidPreview from '../components/base/vidPreviewCard/previewCard';
import { useStaticQuery, graphql } from "gatsby" 
import Banner from "../components/composite/banner";



const Main = ()=> {

    const {allContentfulFeatures} = useStaticQuery(graphql`
    query Previews {
        allContentfulFeatures(filter: {node_locale: {eq: "en-US"}}) {
          edges {
            node {
              callToAction
              header
              href2
              additionalInfo {
                additionalInfo
              }
              image {
                file {
                    url
                }
              }
              order
            }
          }
        }
    }
  `)


    return (
      <>
         <div className={previewCardListContainer}>
       
            {allContentfulFeatures.edges
            .sort((a:{node:{order:number}},b:{node:{order:number}})=>a.node.order - b.node.order)
            .map(({node}:any) => (
                <VidPreview key={node.header} title={node.header} to={node.href2} src={node.image.file.url} cta={node.callToAction} type={node.additionalInfo.additionalInfo}/>
            ))}
        </div>
        </>
      
    );
};



export default Main;